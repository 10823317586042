@import url(https://fonts.googleapis.com/css?family=Ibarra+Real+Nova&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
body {
  background-color: #FBFBFB!important;

  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Ibarra Real Nova', serif;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
    margin: 0;
    padding: 0;
}
.audit-wrapper-mobile{
    padding: 20px;
    /* border:1px solid  black; */
    position: relative;
}
.values-grid b,p{
    font-family: 'Lato', sans-serif;
    color: #464646; 
    font-size: 16px;
}
.event-information p,ol,li{
    color: #464646!important;
    font-family: 'Lato', sans-serif;
}
.event-information ol ,li{
    margin-left: 15px;
}
.careers-collapse ul li,ol{
    font-family: 'Lato', sans-serif;
    /* font-size: 45px; */
    color: #464646; 
    margin-left: 15px;
}
.audit-color-card1{
    background-color: #ccecfb;
}
.audit-color-card2{
    background-color: #d3ccfb;
}
.audit-color-card3{
    background-color: #fbccd5;
}
.audit-card-mobile{
    min-height: 100px;
    box-shadow: 0 0 15px 0 rgba(37, 37, 37, 0.16);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.audit-card-mobile h3{
    font-size: 24px;
    font-weight: bold;
    color: #464646;
}
.audit-card-mobile p{
    color: #464646;
}
.btn-remove-capital{
    text-transform: none!important;
  }
.blue-section{
    background-color: #00a1ed;
    padding: 20px;
    height: 90%;
    display: flex;
    flex-direction: column;
}
.blue-header{
    display: flex;
}
.blue-header h3{
    font-size: 24px;
    color:white
}
.blue-header p{
    color: white;
}
.div-flex{
    display: flex;
}
.div-justify-between{
    justify-content: space-between;
}
.div-justify-center{
    justify-content: center;
}
.div-align-center{
    align-items: center;
}
.progress-bar{
    margin-top: 30px;
}
.progress-title p{
    color: white;
    margin: 0;
    font-size: 18px;
    font-weight: bold;
}
.bar{
    width: 100%;
    height: 13px;
    margin-top: 10px;
    background-color: #9b003c!important;
    border-radius: 10px;
}
.bar-desktop{
    width: 100%;
    height: 10px;
    margin-top: 10px;
    background-color: #9b003c!important;
    border-radius: 10px;
}
.bar-dtra{
    width: 100%;
    height: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    background-color: #00a0ec!important;
    border-radius: 10px;
}
progress {
    /* style rules */
    border-radius: 10px;
  }
  progress::-webkit-progress-bar {
    background-color: white!important;
    border-radius: 10px;

    /* style rules */
  }
  progress::-webkit-progress-value {
    background-color: #9b003c!important;
    border-radius: 10px 10px 10px 10px;
    /* style rules */
  }
  progress::-moz-progress-bar {
    background-color: #9b003c!important;
    border-radius: 10px 10px 10px 10px;

  }
  .bar-dtra::-webkit-progress-bar {
    background-color: #e1f3fa!important;
    border-radius: 10px;

    /* style rules */
  }
  .bar-dtra{
      margin:0
  }
  .bar-dtra::-webkit-progress-value {
    background-color: #00a0ec!important;
    border-radius: 10px 10px 10px 10px;
    /* style rules */
  }
  .bar-dtra::-moz-progress-bar {
    background-color: #00a0ec!important;
    border-radius: 10px 10px 10px 10px;

  }
.blue-title{
    text-align: center;
    /* z-index: 3; */
    /* position: sticky; */
    /* margin-top:100px; */

}
.blue-title h4{
    color: red;
    font-size: 32px;
}
.language{
    display: flex;
    justify-content: flex-end;
}
.language p{
    font-weight: bold;
}
.language-desktop{
    display: flex;
    justify-content: flex-end;
}
.language-desktop p{
    font-size: 14px;
    cursor: pointer;
    font-weight: bold;
}
.color-grey{
    color: #777777;

}
.color-blue{
    color: #00a1ed!important;
}
.color-red{
    color: #ed4900!important;
}

/* .white-section{
    height: 300px;
    background-color: white;
    position: absolute;
    width: 100%;
    bottom: 0px;
    z-index: 1;
    border-radius: 20px 20px 0px 0px;
} */
.question-wrapper{
    height: 100vh;
    /* border:1px solid black; */
    position: relative;
}
.white-wrapper{
    position: absolute;
    bottom: 0px;
    max-height: 560px;
    z-index: 1;
    width: 100%;
    /* border:1px solid red */

}
.title-question{
    text-align: center;
}
.title-question h4{
    font-size: 32px;
    color: white;
}
.question{
    /* height: 300px; */
    background-color: white;
    /* position: absolute; */
    /* bottom: 0px; */
    /* z-index: 1; */
    
    border-radius: 20px 20px 0px 0px;
}
.question-content{
    max-height: 440px;
    overflow-y: auto;
    overflow-x: hidden;
    /* border: 1px solid green; */
}
.question-content p{
    font-size: 22px;
    color: #464646;
    /* font-weight: 600; */
}
.question-content h3{
    font-family: 'Lato', sans-serif;
    color: #464646;
    font-size: 24px;
}
.question-content-desktop{
    /* max-height: 450px;
    overflow-y: auto;
    overflow-x: hidden; */
    /* max-height: 440px;
    overflow-y: auto;
    overflow-x: hidden; */
    /* border: 1px solid green; */
    /* text-align: center; */
}
.question-content-desktop p{
    font-size: 20px;
    color: #252525;
    /* font-weight: 600; */
}
.question-content-desktop h3{
    font-family: 'Lato', sans-serif;
    color: #464646;
}
.label-field{
    font-size: 18px!important;

}
.remove-capital{
    text-transform: none!important;
}
.text-center{
    text-align: center;
}
.question-option>div{
    margin-top: 20px;
    /* height: 50px; */
    border:1px solid #eeeeee;
    padding: 10px;
    border-radius: 10px;
    background-color: #f7fdff;
    cursor: pointer;
}
.question-option>div p{
    font-size: 20px;
    color: #777777;
    font-weight: bold;
}
.question-option-desktop>div{
    margin-top: 20px;
    /* height: 50px; */
    border:1px solid #eeeeee;
    padding: 10px;
    border-radius: 10px;
    background-color: #f7fdff;
    cursor: pointer;
}
.question-option-desktop>div p{
    font-size: 16px;
    color: #777777;
    font-weight: bold;
}
.option-active{
    background-color: #00a1ed!important;
}
.option-active p{
    color: white !important;

}
.question-filled{
    color:#00a1ed !important;
}
.question-filled p{
    color:#00a1ed ;
}
.question-filled:before{
    color:#00a1ed!important ;
    
}
.question-filled-desktop{
    color:white !important;
    font-weight: normal;

}
.question-filled-desktop p{
    color:white ;
    font-weight: normal;

}
.question-filled-desktop:before{
    color:white!important ;
    
}
.question-black{
    color:black !important;
    font-weight: normal;

}
.question-black p{
    color:black ;
    font-weight: normal;
}
.question-black:before{
    color:black!important ;
    
}
.daftar-kuesioner{
    font-size: 20px;
    font-weight: bold;
}
.daftar-kuesioner-desktop{
    font-size: 18px;
    /* font-weight: bold; */
    color: white;
}
.color-black{
    color:black!important;
    font-weight: bold;
}
.question h1{
    color: #464646;
}
.list-ol{
    margin-left: 17px;
    font-size: 22px;
    font-weight: bold;
    list-style-type:li;
    color: red!important;
}
.list-ol li::before{
    background-color: red;
}
.list-ol p{
    font-size: 22px;
    margin: 20px;
}
.list-ol2{
    margin-left: 0px;
    font-size: 22px;
    font-weight: bold;
    list-style-type:li;
    color: red!important;
}
.list-ol2 p{
    font-size: 22px;
    margin: 0px;
}
.list-ol-desktop{
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
    list-style-type:li;
    color: black!important;
}

.list-ol-desktop p{
    font-size: 14px;
    margin: 15px;
    font-weight: bold;
}
.list-ol-desktop2{
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
    list-style-type:li;
    color: black!important;
}

.list-ol-desktop2 p{
    font-size: 14px;
    font-weight: bold;

    margin: 15px 15px 15px -10px;
}


.audit-wrapper-desktop{
    display: grid;
    grid-template-columns: repeat( 3,1fr);
   grid-row-gap: 80px;
   grid-column-gap: 20px;
   width: 84%;
   margin: auto;
}
.audit-wrapper-desktop>div{
    height: 150px;
    /* border:1px solid red; */
    text-align: center;
}
.audit-card-desktop{
    padding: 20px;
    height: 100px;
    /* border:1px solid black; */
    text-align: left;
    box-shadow: 0 0 15px 0 rgba(37, 37, 37, 0.16);
}
.audit-card-desktop h3, p{
    color: #464646;
}

.question-wrapper-desktop{
    background-color: #00a1ed;
    padding: 20px 60px 20px 60px;
    min-height: 95vh;
}

.question-desktop{
    display: flex;
}
.question-list{
    width: 40%;
    height: 500px;
    /* border:1px solid black; */
    padding: 10px;
}

.scroll-question::-webkit-scrollbar
{
    width: 12px;
	border-radius: 10px;
    
	background-color: #F5F5F5;
}

.scroll-question::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	/* background-color: #555; */
}
.question-content-desktop{
    width: 100%;
    /* border:1px solid black; */
    padding: 10px;

}
.question-content-desktop h1{
    font-family: 'Lato', sans-serif;
    color: #252525;
}
.color-white{
    color: white!important;
}

.question-white-section{
    width: 95%;
    min-height: 300px;
    border-radius: 20px;
    background-color: white;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    max-height: 460px;
    overflow-y: auto;
    overflow-x: hidden;
    /* border:1px solid rgb(80, 28, 28); */
    /* max-height: 450px; */
    /* overflow-y: auto;
    overflow-x: hidden; */
    /* padding-left: 20px;
    padding-right: 20px; */
    padding: 20px 20px 20px 20px;
    
}
.question-white-section2{
    width: 95%;
    min-height: 300px;
    border-radius: 20px;
    background-color: white;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    max-height: 460px;
    overflow-y: auto;
    overflow-x: hidden;
    /* border:1px solid rgb(80, 28, 28); */
    /* max-height: 450px; */
    /* overflow-y: auto;
    overflow-x: hidden; */
    /* padding-left: 20px;
    padding-right: 20px; */
    padding: 20px 40px 20px 40px;
    
}


.report-wrapper{
    width: 75%;
    margin:auto
}
body{
    -webkit-print-color-adjust: exact;
}
.expired-wrapper{
    text-align: center;
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.expired-wrapper img{
    width: 30%;
}
.expired-wrapper h1{
    font-family: 'Lato', sans-serif;
    /* color: #252525; */
    font-size: 20px;
    margin:10px 0px 10px 0px;
}
@media screen and (max-width:768px){
    .bar-dtra{
        height: 20px;
    }
    .expired-wrapper img{
        width: 100%;
    }  
    .expired-wrapper h1{
        font-size: 16px;
    }  
}
.pagebreak { 
    margin-top: 100px;
}
@media print 
{
    @page {
      size: A4; /* DIN A4 standard, Europe */
      margin:0;
    }
    
    .report-wrapper{
        width: 100%;

    }
    
    .report-header,.report-footer{
        width: 100%!important;
        /* z-index: 1030; */
        /* -webkit-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0); */
        /* position: fixed; */
    }
    .report-header{
        margin-bottom: 250px;

    }
    .pagebreak { page-break-before: always; }
    .pagebreak2 { page-break-after: auto; }
    .report-h1-title{
        font-size: 40px!important;
        color: #00a1ed;
    }
    .report-shape4{
        /* height: 40px!important; */
        /* border:1px solid black */
    }
    
}
.report-shape{
    margin: 0px 0px 0px auto;
    width: 600px;
    height: 40px;
    background-image: linear-gradient(to right, #00a1ed, #9b013c);
    border-radius: 0px 0px 0px 100px;
}
.report-shape2{
    margin: 0px 0px 0px auto;
    width: 600px;
    height: 40px;
    background-image: linear-gradient(to right, rgba(0, 161, 237, 0.5), rgba(155, 1, 60, 0.5));
    border-radius: 0px 0px 0px 100px;
}
.report-shape3{
    margin: 0px auto 0px 0px;
    width: 95%;
    /* height: 40px; */
    background-image: linear-gradient(to right, rgba(0, 161, 237, 0.5), rgba(155, 1, 60, 0.5));
    /* border-radius: 0px 0px 0px 100px; */
}
.report-shape3 h1{
    font-size: 22px;
    color: white;
    padding: 10px 10px 10px 50px;
}
.report-shape4{
    margin: 0px auto 0px 0px;
    width: 100%;
    /* height: 40px; */
    background-image: linear-gradient(to right, rgba(0, 161, 237, 0.5), rgba(155, 1, 60, 0.5));
    /* border-radius: 0px 0px 0px 100px; */
}
.report-shape4 h1{
    font-size: 22px;
    color: white;
    padding: 10px 10px 10px 50px;
}
.report-h1-title{
    font-size: 70px;
    color: #00a1ed;
}

.vertical-title{
    color: rgba(155, 1, 60, 0.05);
    font-size: 80px;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    position: absolute;
    right: -220px;
    top: 120px;
}
.vertical-text{
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}
.report-title{
    position: relative;

    /* border:1px solid black; */
    min-height: 150px;
}

.report-information p{
    font-size: 18px;
    /* font-weight: bold; */
    margin-bottom: 20px;
}
.text-bold{
    font-weight: bold!important;
}
.report-map{
    text-align: center;
}
.report-map img{
    width: 90%;
}
.report-header{
    position: fixed;
    top:0;
    width: 75%;
    /* border:1px solid black; */
    background-color: white;
    z-index: 1;
    margin-bottom: 100px;
}
.report-footer{
    position: fixed;
    bottom:0;
    width: 75%;
    height: 50px;
    background-color: white;
    z-index: 1;
}
.report-footer p{
    font-size: 18px;
    font-weight: bold;
}

.page2{
    margin-top: 100px;
}

.div-margin{
    width: 90%;
    margin: auto;
}
.div-margin2{
    width: 90%;
    margin: auto;
}
.strategic-text p{
    color:#464646;
    /* font-weight: 600; */
}

.div-table p{
    font-size: 14px;
    font-weight: normal;
}
.border-bottom-table{
    border-bottom: 1px solid #eeeeee;
}
.header-space, .footer-space{
    height: 110px;
  }

.content{
    width: 100%!important;
}
.mini-hr-result{
    width: 300px!important;
    height: 50px;
    border: solid 1px #00a1ed;
  background-color: rgba(0, 161, 237, 0.2);
}
.mini-hr-result p{
    font-weight: bold;
    font-size: 20px;
    
}
.mini-hr-desc{
    width: 55%;
}
.mini-hr-desc p{
    /* font-weight: bold; */
    font-size: 18px;
}

.card-ranking{
    width:95%;
    /* min-height: 40px; */
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    margin-top: 10px;
    border-radius: 20px;
    padding: 20px;
    /* padding: 13px 10px 8px 10px; */
}

.add-participant{
    width:80%;
    border:1px dashed #00a1ed;
   
    display:flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background-color: #ccecfc  ;
    cursor: pointer;

}
.add-participant p{
    color:  #00a1ed;
}
.add-participant2{
    width:100%;
    border:1px dashed #00a1ed;
   
    display:flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background-color: #ccecfc  ;
    cursor: pointer;

}
.add-participant2 p{
    color:  #00a1ed;
}
.semi-bold{
    /* font-weight: bold; */
    color: #777777;
    font-size: 18px;
  font-weight: 600;
    margin-top:0;
    font-size: 14px;
  font-weight: 600;
  /* margin:5px; */
  }

  .card-content-item-jurnal-text{
    /* color: #777777!important; */
    margin:2px!important;
    font-size: 13px!important;
    font-weight: 600!important;
    padding:6px!important;
}
  .card-content-item-jurnal-text-without-border{
    /* color: #777777!important; */
    margin:2px!important;
    font-size: 13px!important;
    font-weight: 600!important;
    padding:10px!important;
    border-bottom: 0px!important;
}
.card-content-item-jurnal-text-without-weight{
    color: #777777!important;
    margin:0!important;
    font-size: 13px!important;
    font-weight: normal!important;
    padding:0!important
}

.hrbp-grid{
    display: grid;
    grid-template-columns: repeat( 2,1fr);
   grid-row-gap: 20px;
   grid-column-gap: 20px;
}
.show-mobile{
    display: none!important;
}
/* .hide-mobile{
    display: flex!important;
} */
@media screen and (max-width:768px){
    .hide-mobile{
        display: none!important;
    }
    .show-mobile{
        display: block!important;
    }
    .report-header{
        width: 100%;
        /* border:1px solid black; */
        height: 120px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .report-wrapper{
        width: 100%;
    }
    .content{
        margin-top: 20px;
    }
    .report-footer{
        width: 100%;
    }
    .report-shape{
        width: 300px;
        /* border:1px solid black */
    }
}
.modal{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    z-index: 5000;

    position: fixed!important;
}
.modal-2{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    z-index: 5000;

    position: fixed!important;
}
.modal-content{
    width:400px;
    outline: 0px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    /* overflow-y: scroll; */
}
.modal-header{
    padding:20px;
    /* height: 50px; */;
    display: flex;
    justify-content: space-between;
    /* border-bottom-style: dashed ;
    border-bottom-color: #ccc;
    border-bottom-width: medium; */
}
.modal-header img{
    cursor: pointer;
}
.modal-header h2{
    font-family: 'Lato', sans-serif;
    color: #252525;
    font-size: 20px;
}
.modal-body{
    padding:10px
}
.modal-content hr{
    border:1px dashed  #ccc;
}

@media screen and (max-width:768px){
    .modal-content{
        width:100%;
        outline: 0px;
        border-radius: 5px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
    }
    .modal-header h2{
        font-size: 18px;
    }
    .modal{
        align-items: flex-end;
    }
    /* .modal{
        align-items: flex-end;
    } */
}
.loading-wrapper{
    position: fixed;
    z-index: 500;
    height: 100vh;
    width: 100%;
    background-color: #00000085;
    display: flex;
    justify-content: center;
    align-items: center;
}
.welcome-wrapper {
  display: flex;
  width: 90%;
  margin: auto;
  height: 99vh;

  /* border:1px solid black */
}

.welcome-text {
  /* border:1px solid red; */
  width: 40%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.welcome-example {
  /* border:1px solid blue; */
  width: 60%;
}
.shape-img {
  width: 100px;
  position: absolute;
}
.welcome-text h2 {
  font-size: 20px;
  font-weight: bold;
  color: #00a1ed;
  font-family: "Lato", sans-serif;
}
.welcome-text span {
  font-weight: bold;
}
.welcome-text p {
  color: #808080;
  margin: 30px 0px 30px 0px;
  width: 300px;
}

.welcome-example {
  padding: 20px;
  display: flex;
  align-items: center;
}
.card-example {
  width: 650px;
  min-height: 250px;
  border-radius: 20px;
  box-shadow: 0 0 30px 0 rgba(37, 37, 37, 0.25);
  background-color: white;
  z-index: 2;
  position: relative;
}

.card-example {
  /* padding: 20px; */
}
.card-example h3 {
  color: #00a1ed;
  font-family: "Lato", sans-serif;
  font-weight: bold;
}
.card-example p {
  color: #252525;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* font-weight: 500; */
}
.btn-rounded {
  border-radius: 20px !important;
}

.square-1,
.square-2 {
  width: 30%;
  /* height: 200px; */
  /* border:1px solid black; */
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.square-middle {
  width: 40%;
  /* height: 200px; */
  /* border:1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: white; */
  /* z-index: 1; */
}
.kolom-isi {
  padding: 0px 20px 0px 20px;
  /* display: flex!important; */
}
.kolom-isi2 {
  padding: 0px 20px 0px 20px;
  /* display: flex; */
  width: 70%;
}
.kolom-isi-mobile {
  padding: 10px 0px 20px 0px;
  display: none;
}
.add-proposal__field {
  width: 100%;
  margin-bottom: 15px !important;
}
.field-radius {
  border-radius: 10px !important;
}
.bg-pertanyaan-mobile {
  display: none;
  justify-content: space-between;
}
.field-kolom {
  padding: 0;
}
.result-wrapp h2 {
  font-family: "Lato", sans-serif;
}
.result-wrapp p {
  font-weight: normal;
  font-size: 16px;
}
.div-justify-center {
  justify-content: center;
}
.pemetaan p {
  margin: 10px;
}
.demografi-mobile {
  display: none;
}
.flex-wrap {
  flex-wrap: wrap;
}
.div-grid {
  display: grid;
  margin: 0;
  grid-template-columns: repeat(auto-fit, minmax(1rem, 1fr));
  /* grid-row-gap: 20px;
   grid-column-gap: 20px; */
}
.form-demografi {
  display: flex;
  /* justify-content: space-between; */
  /* flex-wrap: wrap; */
}
.tdra-field {
  width: 100%;
}
.form-50 {
  width: 50%;
}
.form-80 {
  width: 80%;
}
.help {
  width: 20px;
  height: 20px;
  background-color: #00a1ed;
  color: white;
  font-family: "Lato", sans-serif;
  border-radius: 100%;
  text-align: center;
  cursor: pointer;
}
.help-wrapper {
  position: absolute;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
  width: 400px;
  /* border:1px solid black; */
  /* height: 100px; */
  padding: 10px;
  right: 30px;
  top: 50px;
  z-index: 2;
}
.help-wrapper p {
  font-size: 12px;
}
.help-backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.help-mobile {
  padding: 10px;
  /* height: 100px; */
  /* border:1px solid black; */
}
.help-mobile p {
  font-size: 14px;
  font-weight: 600;
}
.demografi-wrapper {
  padding: 20px 20px 0px 20px;
}
.demografi-wrapper::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;

  background-color: #f5f5f5;
}

.demografi-wrapper::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #00a1ed;
}
.demografi-wrapper-fullheight {
  padding: 20px 20px 0px 20px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 400px;
}
.demografi-wrapper-fullheight::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;

  background-color: #f5f5f5;
}

.demografi-wrapper-fullheight::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #00a1ed;
}
.create-link-wrapper {
  padding: 20px 20px 0px 20px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 400px;
}
.create-link-wrapper::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;

  background-color: #f5f5f5;
}

.create-link-wrapper::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #00a1ed;
}
.ranking-fullheight {
  padding: 20px 20px 0px 20px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 400px;
}
.ranking-fullheight::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;

  background-color: #f5f5f5;
}

.ranking-fullheight::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #00a1ed;
}
.radio-height {
  height: 30px;
}

.add-participant-dtra {
  width: 90%;
  border: 1px dashed #00a1ed;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: #ccecfc;
  cursor: pointer;
}
.add-participant-dtra p {
  color: #00a1ed !important;
  align-items: center !important;
}
.create-link-footer {
  display: flex;
  justify-content: space-between;
}
.create-link-footer p {
  font-size: 12px !important;
  color: #777777 !important;
  width: 90%;
}
.create-link-wrapper h2 {
  color: #00a1ed;
  font-family: "Lato";
  margin-bottom: 20px;
}
.instruksi-edtra {
  display: flex;
  justify-content: space-between;
}

.card-pagination {
  font-family: "Open Sans", sans-serif;
  /* font-size: 12px; */
  margin: 0;
}
.card-table__pagination {
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 768px) {
  .instruksi-edtra {
    flex-direction: column;
    align-items: flex-end;
    grid-gap: 20px;
    gap: 20px;
  }
  .instruksi-edtra .agreement {
    align-self: flex-start;
  }
  .demografi-wrapper-fullheight {
    height: 78vh;
  }
  .ranking-fullheight {
    height: 68vh;
  }
  .create-link-wrapper h2 {
    margin-bottom: 0px;
    font-size: 18px;
  }
  .create-link-footer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    align-items: flex-end;
  }
  .create-link-footer p {
    align-self: flex-start;
  }
  .add-participant-dtra {
    width: 100%;
    margin-top: 20px;
  }
  .radio-height {
    height: 50px;
  }
  .help {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
  }
  .help-wrapper {
    position: absolute;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    width: 100%;
    bottom: 0px;
    z-index: 2;
    padding: 10px;
  }
  .help-backdrop {
    position: absolute;
    width: 90%;
    height: 99vh;
    z-index: 1;
    border: 1px solid black;
  }
  .tdra-field {
    width: 100%;
  }
  .form-50 {
    width: 100%;
  }
  .form-80 {
    width: 100%;
  }
  .form-demografi {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .bg-pertanyaan-mobile {
    display: flex;
  }
  .demografi-mobile {
    display: block;
  }
  .kolom-isi {
    /* flex-direction: column; */
    padding: 0px;
  }
  .kolom-isi2 {
    /* flex-direction: column; */
    padding: 0px;
  }
  .kolom-isi-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* height: 330px; */
  }
  .shape-img {
    display: none;
  }
  .welcome-wrapper {
    flex-direction: column;
    width: 100%;
    height: 99vh;
  }
  .welcome-text {
    width: 100%;
    /* padding: 20px 20px 0px 20px; */
  }
  .welcome-mobile {
    padding: 20px 0px 0px 20px;
    /* display: flex; */
  }
  .welcome-text p,
  b {
    color: #808080;
    font-size: 16px;
  }
  .welcome-example {
    width: 100%;
    padding: 0px;
  }
  .card-example {
    padding: 0px;
    box-shadow: none;
    width: 100%;
    height: 99vh;
  }
  .card-example p {
    font-size: 16px;
    line-height: 20px;
    /* color: #808080        ; */
  }
  .square-1,
  .square-2 {
    box-shadow: 0 0 30px 0 rgba(37, 37, 37, 0.25);
    width: 100%;
    border-radius: 20px;
    /* margin-bottom: 25px; */
  }
  .square-middle {
    width: 20%;
  }
  .kolom-isi2 {
    /* padding:0px 20px 0px 20px; */
    /* display: flex; */
    width: 100%;
  }
  .field-kolom {
    padding: 20px 20px 0px 20px;
  }
  .result-wrapp {
    /* padding: 20px; */
  }
  .footer-form {
    position: absolute;
    bottom: 0;
    /* margin-top: 100px; */
  }
}

.welcome-wrapper{
    display: flex;
    width: 90%;
    margin: auto;
    height: 99vh;

    /* border:1px solid black */
}

.welcome-text{
    /* border:1px solid red; */
    width: 40%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.welcome-example{
    /* border:1px solid blue; */
    width: 60%;
}
.shape-img{
    width: 100px;
    position: absolute;
}
.welcome-text h2{
    font-size: 20px;
    font-weight: bold;
    color: #00a1ed;
    font-family: 'Lato', sans-serif;
}
.welcome-text span{
    font-weight: bold;
}
.welcome-text p{
    color: #808080;
    margin:30px 0px 30px 0px;
    width:300px

}

.welcome-example{
    padding: 20px;
    display: flex;
    align-items: center;
}
.card-example{
    width: 650px;
    min-height: 250px;
    border-radius: 20px;
    box-shadow: 0 0 30px 0 rgba(37, 37, 37, 0.25);
    background-color: white;
    z-index: 2;
    position: relative;
    

}

.card-example{
    /* padding: 20px; */

}
.card-example h3{
    color: #00a1ed;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
}
.card-example p{
    color:#252525;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* font-weight: 500; */
}
.btn-rounded{
    border-radius: 20px!important;
  }

.square-1,.square-2{
    width:30%;
    /* height: 200px; */
    /* border:1px solid black; */
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.square-middle{
    width:40%;
    /* height: 200px; */
    /* border:1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: white; */
    /* z-index: 1; */
}
.kolom-isi{
    padding:0px 20px 0px 20px;
    display: flex;
}
.kolom-isi2{
    padding:0px 20px 0px 20px;
    /* display: flex; */
    width: 70%;
}
.kolom-isi-mobile{
    padding:10px 0px 20px 0px;
    display: none;
}
.add-proposal__field{
    width: 100%;
    margin-bottom: 15px!important;
}
.field-radius{
    border-radius: 10px!important;

}
.bg-pertanyaan-mobile{
    display: none;
    justify-content: space-between;

}
.field-kolom{
    padding:0
}
.result-wrapp h2{
    font-family: 'Lato', sans-serif;
    
}
.result-wrapp p{
    font-weight: normal;
    font-size: 16px;
}
.div-justify-center{
    justify-content: center;
}
.pemetaan p{
    margin:10px
}
.demografi-mobile{
    display: none;
}
.flex-wrap{
    flex-wrap: wrap;
}
.div-grid{
    display: grid;
    margin: 0;
    grid-template-columns: repeat( auto-fit,minmax(1rem,1fr));
   /* grid-row-gap: 20px;
   grid-column-gap: 20px; */
}
.form-demografi{
    display: flex;
    /* justify-content: space-between; */
    /* flex-wrap: wrap; */
}
.tdra-field{
    width: 100%;
}
.form-50{
    width:50%
}
.form-80{
    width:80%
}
.help{
    width: 20px;
    height: 20px;
    background-color: #00a1ed;
    color: white;
    font-family: 'Lato', sans-serif;
    border-radius: 100%;
    text-align: center;
    cursor: pointer;
}
.help-wrapper{
    position: absolute;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    width: 400px;
    /* border:1px solid black; */
    /* height: 100px; */
    padding: 10px;
    right: 30px;
    top: 50px;
    z-index: 2;
}
.help-wrapper p{
    font-size: 12px;
}
.help-backdrop{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.help-mobile{
    padding:10px ;
    /* height: 100px; */
    /* border:1px solid black; */
}
.help-mobile p{
    font-size: 14px;
    font-weight: 600;
}
.demografi-wrapper{
    padding: 20px 20px 0px 20px;
    overflow-x: hidden;
    overflow-y: auto;
    height: 400px;
}
.demografi-wrapper::-webkit-scrollbar
{
    width: 10px;
	border-radius: 5px;
    
	background-color:  #F5F5F5;
}

.demografi-wrapper::-webkit-scrollbar-thumb
{
	border-radius: 5px;
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
	background-color: #00a1ed;
}
.radio-height{
    height: 30px;
}

.lps-flex{
    width: 100%;
    display:grid;
    grid-template-columns: repeat( 2,1fr);
    grid-row-gap:10
}
@media screen and (max-width:768px){
    /* .demografi-wrapper{
        padding: 20px 20px 0px 20px;
        overflow-x: hidden;
        overflow-y: auto;
        height: unset;
    } */
    .lps-flex{
        grid-template-columns: repeat( 1,1fr);
    grid-row-gap:3

    }
    .radio-height{
        height: 50px;
    }
    .help{
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: bold;
    }
    .help-wrapper{
        position: absolute;
        border-radius: 4px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: white;
        width: 100%;
        bottom: 0px;
        z-index: 2;
        padding: 10px;

    }
    .help-backdrop{
        position: absolute;
        width: 90%;
        height: 99vh;
        z-index: 1;
        border:1px solid black
    }
    .tdra-field{
        width: 100%;
    }
    .form-50{
        width:100%
    }
    .form-80{
        width:100%
    }
    .form-demografi{
        display: grid;
        grid-template-columns: repeat( 2,1fr);
    }
    .bg-pertanyaan-mobile{
        display: flex;
    }
    .demografi-mobile{
        display: block;
    }
    .kolom-isi{
        /* flex-direction: column; */
        padding: 0px;
    }
    .kolom-isi2{
        /* flex-direction: column; */
        padding: 0px;
    }
    .kolom-isi-mobile{
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* height: 330px; */
    }
    .shape-img{
        display: none;
    }
    .welcome-wrapper{
        flex-direction: column;
        width: 100%;
        height: 99vh;
    }
    .welcome-text{
        width: 100%;
        /* padding: 20px 20px 0px 20px; */
    }
    .welcome-mobile{
        padding: 20px 0px 0px 20px;
        /* display: flex; */
    }
    .welcome-text p, b{ 
        color: #808080;
        font-size: 16px;
    }
    .welcome-example{
        width: 100%;
        padding: 0px;
    }
    .card-example{
        padding: 0px ;
        box-shadow: none;
        width: 100%;
        height: 99vh;
    }
    .card-example p{
        font-size: 16px;
        line-height: 20px;
        /* color: #808080        ; */
    }
    .square-1,.square-2{
        box-shadow: 0 0 30px 0 rgba(37, 37, 37, 0.25);
        width: 100%;
        border-radius: 20px;
        /* margin-bottom: 25px; */
    }
    .square-middle{
        width: 20%;
    }
    .kolom-isi2{
        /* padding:0px 20px 0px 20px; */
        /* display: flex; */
        width: 100%;
    }
    .field-kolom{
        padding:20px 20px 0px 20px
    }
    .result-wrapp{
        /* padding: 20px; */
    }
    .footer-form{
        position: absolute;
        bottom:0;
        /* margin-top: 100px; */
    }
}
.welcome-wrapper{
    display: flex;
    width: 90%;
    margin: auto;
    height: 99vh;

    /* border:1px solid black */
}

.welcome-text{
    /* border:1px solid red; */
    width: 40%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.welcome-example{
    /* border:1px solid blue; */
    width: 60%;
}
.shape-img{
    width: 100px;
    position: absolute;
}
.welcome-text h2{
    font-size: 20px;
    font-weight: bold;
    color: #00a1ed;
    font-family: 'Lato', sans-serif;
}
.welcome-text span{
    font-weight: bold;
}
.welcome-text p{
    color: #808080;
    margin:30px 0px 30px 0px;
    width:300px

}

.welcome-example{
    padding: 20px;
    display: flex;
    align-items: center;
}
.card-example{
    width: 100%;
    min-height: 250px;
    border-radius: 20px;
    box-shadow: 0 0 30px 0 rgba(37, 37, 37, 0.25);
    background-color: white;
    z-index: 2;
    position: relative;
    

}

.card-example{
    /* padding: 20px; */

}
.card-example h3{
    color: #00a1ed;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
}
.card-example p{
    color:#252525;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* font-weight: 500; */
}
.btn-rounded{
    border-radius: 20px!important;
  }

.square-1,.square-2{
    width:30%;
    /* height: 200px; */
    /* border:1px solid black; */
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.square-middle{
    width:40%;
    /* height: 200px; */
    /* border:1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: white; */
    /* z-index: 1; */
}
.kolom-isi{
    padding:0px 20px 0px 20px;
    display: flex;
}
.kolom-isi2{
    padding:0px 20px 0px 20px;
    /* display: flex; */
    width: 70%;
}
.kolom-isi-mobile{
    padding:10px 0px 20px 0px;
    display: none;
}
.add-proposal__field{
    width: 100%;
    margin-bottom: 15px!important;
}
.field-radius{
    border-radius: 10px!important;

}
.bg-pertanyaan-mobile{
    display: none;
    justify-content: space-between;

}
.field-kolom{
    padding:0
}
.result-wrapp h2{
    font-family: 'Lato', sans-serif;
    
}
.result-wrapp p{
    font-weight: normal;
    font-size: 16px;
}
.div-justify-center{
    justify-content: center;
}
.pemetaan p{
    margin:10px
}
.akhlak-bold{
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    color: #464646;
    font-size: 18px;
    text-align: left;
    border-bottom: 1.1px solid #707070;
    padding: 10px;
}
.akhlak-bold2{
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    color: #464646;
    font-size: 14px;
    text-align: left;
    border-bottom: 1.1px solid #707070;
    padding: 5px;

}
.pertanyaan-container{
    padding: 20px 20px 0px 20px;
    /* height: 0; */
}
@media screen and (max-width:768px){
    .pertanyaan-container{
        padding: 20px 20px 0px 20px;
        height: 80vh;
    }
    .bg-pertanyaan-mobile{
        display: flex;
    }
    .kolom-isi{
        /* flex-direction: column; */
        padding: 0px;
    }
    .kolom-isi2{
        /* flex-direction: column; */
        padding: 0px;
    }
    .kolom-isi-mobile{
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* height: 330px; */
    }
    .shape-img{
        display: none;
    }
    .welcome-wrapper{
        flex-direction: column;
        width: 100%;
        height: -webkit-fit-content;
    }
    .welcome-text{
        width: 100%;
        /* padding: 20px 20px 0px 20px; */
    }
    .welcome-mobile{
        padding: 20px 0px 0px 20px;
        /* display: flex; */
    }
    .welcome-text p, b{ 
        color: #808080;
        font-size: 16px;
    }
    .welcome-example{
        width: 100%;
        padding: 0px;
    }
    .card-example{
        padding: 0px ;
        box-shadow: none;
    }
    .card-example p{
        font-size: 16px;
        line-height: 20px;
        /* color: #808080        ; */
    }
    .square-1,.square-2{
        box-shadow: 0 0 30px 0 rgba(37, 37, 37, 0.25);
        width: 100%;
        border-radius: 20px;
        /* margin-bottom: 25px; */
    }
    .square-middle{
        width: 20%;
    }
    .kolom-isi2{
        /* padding:0px 20px 0px 20px; */
        /* display: flex; */
        width: 100%;
    }
    .field-kolom{
        padding:20px 20px 0px 20px
    }
    .result-wrapp{
        /* padding: 20px; */
    }
}
.welcome-wrapper{
    display: flex;
    width: 90%;
    margin: auto;
    height: 99vh;

    /* border:1px solid black */
}

.welcome-text{
    /* border:1px solid red; */
    width: 40%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.welcome-example{
    /* border:1px solid blue; */
    width: 60%;
}
.shape-img{
    width: 100px;
    position: absolute;
}
.welcome-text h2{
    font-size: 20px;
    font-weight: bold;
    color: #00a1ed;
    font-family: 'Lato', sans-serif;
}
.welcome-text span{
    font-weight: bold;
}
.welcome-text p{
    color: #808080;
    margin:30px 0px 30px 0px;
    width:300px

}

.welcome-example{
    padding: 20px;
    display: flex;
    align-items: center;
}
.card-example{
    width: 100%;
    min-height: 250px;
    border-radius: 20px;
    box-shadow: 0 0 30px 0 rgba(37, 37, 37, 0.25);
    background-color: white;
    z-index: 2;
    position: relative;
    

}

.card-example{
    /* padding: 20px; */

}
.card-example h3{
    color: #00a1ed;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
}
.card-example p{
    color:#252525;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* font-weight: 500; */
}
.btn-rounded{
    border-radius: 20px!important;
  }

.square-1,.square-2{
    width:30%;
    /* height: 200px; */
    /* border:1px solid black; */
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.square-middle{
    width:40%;
    /* height: 200px; */
    /* border:1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: white; */
    /* z-index: 1; */
}
.kolom-isi{
    padding:0px 20px 0px 20px;
    display: flex;
}
.kolom-isi2{
    padding:0px 20px 0px 20px;
    /* display: flex; */
    width: 70%;
}
.kolom-isi-mobile{
    padding:10px 0px 20px 0px;
    display: none;
}
.add-proposal__field{
    width: 100%;
    margin-bottom: 15px!important;
}
.field-radius{
    border-radius: 10px!important;

}
.bg-pertanyaan-mobile{
    display: none;
    justify-content: space-between;

}
.field-kolom{
    padding:0
}
.result-wrapp h2{
    font-family: 'Lato', sans-serif;
    
}
.result-wrapp p{
    font-weight: normal;
    font-size: 16px;
}
.div-justify-center{
    justify-content: center;
}
.pemetaan p{
    margin:10px
}
.akhlak-bold{
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    color: #464646;
    font-size: 18px;
    text-align: left;
    border-bottom: 1.1px solid #707070;
    padding: 10px;
}
.akhlak-bold2{
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    color: #464646;
    font-size: 14px;
    text-align: left;
    border-bottom: 1.1px solid #707070;
    padding: 5px;

}
.pertanyaan-container{
    padding: 20px 20px 0px 20px;
    /* height: 0; */
}
.kepemimpinan-radar{
    width: 80%;
}
.radar-kepemimpinan-wrapper{
    display: flex;
    align-items: center;
}
.kepemimpinan-chart{
    /* display: grid;
    grid-template-columns: repeat( 2,1fr); */
   /* grid-row-gap: 20px;
   grid-column-gap: 20px; */
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
}
.kepemimpinan-chart-item{
    width: 46%;
    margin-top: 30px;
}
@media print 
{
    
    .kepemimpinan-chart{
    /* grid-template-columns: repeat( 1,1fr); */

        /* flex-direction: column; */
    }
    .legend-radar-kepemimpinan{
        display: flex;
    }
    .radar-kepemimpinan-wrapper{
        flex-direction: column;
    }
    .kepemimpinan-radar{
        width: 100%;
        flex-direction: column;

    }
    /* .kepemimpinan-chart-item{
        width: 100%;
    } */
}
@media screen and (max-width:768px){
    .legend-radar-kepemimpinan{
        display: flex;
    }
    .radar-kepemimpinan-wrapper{
        flex-direction: column;
    }
    .kepemimpinan-radar{
        width: 100%;
        flex-direction: column;

    }
    .pertanyaan-container{
        padding: 20px 20px 0px 20px;
        height: 80vh;
    }
    .bg-pertanyaan-mobile{
        display: flex;
    }
    .kolom-isi{
        /* flex-direction: column; */
        padding: 0px;
    }
    .kolom-isi2{
        /* flex-direction: column; */
        padding: 0px;
    }
    .kolom-isi-mobile{
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* height: 330px; */
    }
    .shape-img{
        display: none;
    }
    .welcome-wrapper{
        flex-direction: column;
        width: 100%;
        height: -webkit-fit-content;
    }
    .welcome-text{
        width: 100%;
        /* padding: 20px 20px 0px 20px; */
    }
    .welcome-mobile{
        padding: 20px 0px 0px 20px;
        /* display: flex; */
    }
    .welcome-text p, b{ 
        color: #808080;
        font-size: 16px;
    }
    .welcome-example{
        width: 100%;
        padding: 0px;
    }
    .card-example{
        padding: 0px ;
        box-shadow: none;
    }
    .card-example p{
        font-size: 16px;
        line-height: 20px;
        /* color: #808080        ; */
    }
    .square-1,.square-2{
        box-shadow: 0 0 30px 0 rgba(37, 37, 37, 0.25);
        width: 100%;
        border-radius: 20px;
        /* margin-bottom: 25px; */
    }
    .square-middle{
        width: 20%;
    }
    .kolom-isi2{
        /* padding:0px 20px 0px 20px; */
        /* display: flex; */
        width: 100%;
    }
    .field-kolom{
        padding:20px 20px 0px 20px
    }
    .result-wrapp{
        /* padding: 20px; */
    }
}
.engagement-box{
    width: 270px;
    height: 100px;
    background-color: #bde5f8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap:10;
    gap:10;
}

.engagement-box h1 ,h4{
    font-family: 'Lato';
    color: #464646;
}

.engagement-quadrant{
    width: 300px;
    height: 150px;
    border-left: 1px solid #c4c4c4;
    border-bottom: 1px solid #c4c4c4;
    padding: 20px;

    /* border: 0px 0px 1px 1px solid #c4c4c4; */
}

.quadrant-box{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: auto auto;
}
.quadrant-item{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.quadrant-item p{
    font-weight: bold;
}
.item-1{
    background-color: rgba(0, 237, 46, 0.25);
}
.item-2{
    background-color: rgba(0, 161, 237, 0.25)    ;
}
.item-3{
    background-color: rgba(237, 82, 0, 0.25);
}
.item-4{
    background-color: rgba(237, 182, 0, 0.25);
}
