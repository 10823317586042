.engagement-box{
    width: 270px;
    height: 100px;
    background-color: #bde5f8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:10;
}

.engagement-box h1 ,h4{
    font-family: 'Lato';
    color: #464646;
}

.engagement-quadrant{
    width: 300px;
    height: 150px;
    border-left: 1px solid #c4c4c4;
    border-bottom: 1px solid #c4c4c4;
    padding: 20px;

    /* border: 0px 0px 1px 1px solid #c4c4c4; */
}

.quadrant-box{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: auto auto;
}
.quadrant-item{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.quadrant-item p{
    font-weight: bold;
}
.item-1{
    background-color: rgba(0, 237, 46, 0.25);
}
.item-2{
    background-color: rgba(0, 161, 237, 0.25)    ;
}
.item-3{
    background-color: rgba(237, 82, 0, 0.25);
}
.item-4{
    background-color: rgba(237, 182, 0, 0.25);
}